import styled from 'styled-components';
import { font } from '../../../../styles/atoms/typography';
import { atMinWidth } from '../../../../styles/atoms/breakpoints';
import { colors } from '../../../../styles/atoms/colors';

export const DetailWrapper = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  gap: 32px;
`;

export const PersonContainer = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  ${atMinWidth.lg`
    align-items: center;
    flex-direction: row;
  `}

  .name-wrapper {
    gap: 5px;
    display: flex;
    white-space: nowrap;
    flex-direction: column;
    align-items: flex-start;
    color: ${colors.gray[700]};
    ${font('text', 'md', 400)}

    ${atMinWidth.sm`
      align-items: center;
      flex-direction: row;
    `}

    ${atMinWidth.lg`
      align-items: center;
      flex-direction: row;
      white-space: nowrap;
      gap: 5px;
    `}

    .name-bold {
      white-space: nowrap;
      ${font('text', 'md', 700)};
      color: ${colors.gray[900]};
    }
  }
`;

export const VideoWrapper = styled.div`
  border-radius: 12px;
  overflow: hidden;
`;
